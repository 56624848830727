import $ from "jquery";
import 'select2'; // Ensure you've imported select2

const addFieldLocation = () => {
  $("form").on("click", ".add_location_field", function (event) {
    var regexp, time;
    time = new Date().getTime();
    regexp = new RegExp($(this).data("id"), "g");
    var newField = $($(this).data("fields").replace(regexp, time));
    $("#events_locations_container").append(newField);
    newField.find('select').select2(); // Apply select2 to the new field
    return event.preventDefault();
  });
};

export { addFieldLocation };